@use 'sass:meta';
// Custom Theming for Angular Material
@use '@angular/material' as mat;

@function get-mat-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: $font-family,
  $letter-spacing: normal
) {
  @if meta.function-exists('mat-typography-level') {
    @return mat-typography-level(
      $font-size,
      $line-height,
      $font-weight,
      $font-family,
      $letter-spacing
    );
  }

  @return mat.define-typography-level(
    $font-size,
    $line-height,
    $font-weight,
    $font-family,
    $letter-spacing
  );
}

$NA-default-font-size: 400;
$NA-font-family: 'Open sans';
$NA-default-typography: (
  font-family: $NA-font-family,
  display-4: get-mat-typography-level(76px, 76px, 300, $NA-font-family),
  display-3: get-mat-typography-level(56px, 56px, 400, $NA-font-family),
  display-2: get-mat-typography-level(32px, 13px, 700, $NA-font-family),
  display-1: get-mat-typography-level(36px, 36px, 300, $NA-font-family),
  headline: get-mat-typography-level(2.25rem, 1.375, 300, $NA-font-family),
  title: get-mat-typography-level(2rem, 1.375, 400, $NA-font-family),
  subheading-2: get-mat-typography-level(1.75rem, 1.375, 400, $NA-font-family),
  subheading-1: get-mat-typography-level(1.5rem, 1.375, 600, $NA-font-family),
  body-2: get-mat-typography-level(0.875rem, 1.375, 700, $NA-font-family),
  body-1: get-mat-typography-level(14px, 1.375, 400, $NA-font-family),
  button: get-mat-typography-level(13px, 13px, 400, $NA-font-family),
  caption: get-mat-typography-level(0.75rem, 1.375, 400, $NA-font-family),
  input: get-mat-typography-level(inherit, 1.125, 400, $NA-font-family),
);

@font-face {
  font-family: 'Open Sans Bold';
  src: local('Open Sans Bold'),
    url(../assets/fonts/open-sans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Semibold';
  src: local('Open Sans Semibold'),
    url(../assets/fonts/open-sans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Regular';
  src: local('Open Sans Regular'),
    url(../assets/fonts/open-sans/OpenSans-Regular.ttf) format('truetype');
}

@mixin apply-typography() {
  @include mat.core();
  @include mat.core($NA-default-typography);
}

.zonar-default-theme {
  @include apply-typography();
  font-family: $NA-font-family;
  app-card {
    .card-title {
      font-size: 20px;
      line-height: 27px;
    }

    :host::ng-deep {
      .mat-card-title {
        font-size: 24px;

        app-menu {
          i.mat-menu-trigger.material-icons.more_horiz {
            line-height: 27px;
          }
        }
      }
    }
  }

  app-companies {
    h1 {
      font-size: 36px;
      font-weight: 300;
      line-height: 48px;
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  app-chips {
    :host .app-chip-list {
      mat-chip {
        font-size: 14px;
        min-height: 2em;
      }

      .chip-input {
        font-size: 14px;
      }

      mat-error {
        font-size: 10px;
      }
    }
  }

  .form-library .blank-form-field-list .blank-form-field .drop-zone span,
  .form-library .premade-field-list .premade-field .drop-zone span,
  .cdk-drag-preview.premade-field .drop-zone span,
  .cdk-drag-preview.blank-form-field .drop-zone span {
    line-height: 19px;
    font-size: 14px;
    letter-spacing: 0;
  }

  .mat-dialog-title .dialog-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 32px;
  }

  app-confirm-reflect-dialog {
    .mat-dialog-title {
      .dialog-title {
        font-family: $NA-font-family;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.1px;
        line-height: 32px;
      }
    }

    .dialog-content {
      font-family: $NA-font-family;
      font-size: 14px;
    }
  }

  .mat-dialog-actions .mat-button-wrapper {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .unit-switcher-container .unit-item {
    font-size: 12px;
    line-height: 14px;
  }

  snack-bar-container .message {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $NA-font-family;

    .notification-message {
      font-weight: 700;
      font-family: 'Open Sans Bold';

      &.no-detail-error-message {
        font-weight: 600;
        font-family: 'Open Sans Semibold';
      }
    }

    .detailed-error-message {
      font-weight: 400;
      font-family: 'Open Sans Regular';
    }
  }

  app-inspection-detail-row-checkbox-edit-dialog,
  app-inspection-detail-row-dropdown-edit-dialog,
  app-inspection-detail-row-photo-edit-dialog,
  app-add-piece-dialog,
  app-piece-edit-dialog {
    .dialog-content {
      font-family: $NA-font-family;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
  app-dialog {
    .dialog-content {
      font-family: $NA-font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }

  .edit-inspection-type-confirm-dialog {
    .mat-dialog-content .dialog-content {
      line-height: 22px;
    }
  }

  app-add-piece-dialog .dialog-content .name-field {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
  }

  app-piece-edit-dialog .field-name {
    font-size: 14px;
    line-height: 20px;
  }

  .choice-box.cdk-drag-preview {
    font-size: 14px !important;
  }

  .inspection-type-row-edit-form {
    font-size: 14px;
  }

  app-inspection-type-detail {
    .main-details {
      font-size: 14px;

      .detail-row .detail-cell.form-description {
        font-size: 12px;
        line-height: 24px;
      }

      .detail-box.photo {
        .detail-row {
          .detail-cell.form-description {
            line-height: 16px;
          }

          .photo-amount {
            font-size: 32px;
          }
        }
      }
    }

    .additional-information {
      h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;

        span {
          font-weight: normal;
        }
      }

      .detail-cell.checkbox {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .form-library {
    font-size: 14px;

    .premade-field-title {
      font-weight: 600;
    }
  }

  app-inspection-type-card {
    .mat-card-content {
      font-size: 14px;
      line-height: 19px;
    }

    .card-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
    }

    .mat-card-title {
      app-menu i.mat-menu-trigger.material-icons.more_horiz {
        line-height: 19px;
      }
    }
  }

  app-checkbox {
    .title {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
    }

    mat-checkbox {
      font-size: 14px;
    }
  }

  app-inspection-type-edit-dialog,
  app-inspection-type-label-edit-dialog {
    input,
    textarea {
      font-size: 14px;
    }
  }

  // Asset map view
  .asset-zone-map {
    font-family: 'Open sans';

    .asset-zone-map-title {
      .title {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 27px;
      }
    }

    .asset-zone-name {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  // Grid zone box
  app-grid-zone-box {
    .inner-content {
      .box-active {
        .box-name {
          font-style: normal;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0;
        }

        &.box-duplicate:hover {
          .box-name {
            font-size: 15px;
          }
        }

        .multiple-box-name {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .cdk-drag-preview {
    .box-active {
      .box-name {
        font-size: 14px;
        font-weight: 700;
      }

      .multiple-box-name {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  // Asset configurations
  app-config-level-edit-dialog,
  app-zone-level-edit-dialog,
  app-component-level-edit-dialog,
  app-condition-level-edit-dialog {
    .mat-dialog-title .dialog-title {
      font-size: 24px;
      letter-spacing: 0.1px;
      line-height: 32px;
    }

    .dialog-content {
      font-size: 14px;
    }

    .condition-edit-form,
    .zone-edit-form {
      .mat-form-field {
        .mat-form-field-wrapper {
          .mat-form-field-infix {
            .field-name,
            .field-tag-type,
            .field-tag-number {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .primary-button {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;

    &:disabled {
      letter-spacing: 0.3px;
    }
  }

  .special-button {
    @extend .primary-button;
  }

  // Tree component

  app-tree {
    mat-tree-node {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;

      &.active-node {
        font-weight: 600;

        &.active-node.hovering-node {
          font-weight: 600;
        }
      }
    }

    .mat-icon-button {
      line-height: unset;
    }

    .node-content {
      .zone-inspection-type-content {
        font-weight: normal;
      }
    }
  }

  .app-tree-box.cdk-drag-preview {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: 400;
    font-family: $NA-font-family;
  }

  .app-tree-box.cdk-drag-preview.active-node {
    font-weight: 600;
  }

  // Error page
  app-not-found {
    .error-page {
      .page-text {
        .error-header {
          font-size: 45px;
          font-weight: 600;
          letter-spacing: -0.82px;
          line-height: 56px;
        }

        .error-subheader {
          font-size: 20px;
          line-height: 27px;
        }

        .error-contact-text {
          font-size: 18px;
          line-height: 24px;
        }

        .technical-information {
          line-height: 24px;
        }
      }
    }
  }

  app-error-page {
    .error-page {
      .page-text {
        .error-header {
          font-style: normal;
          font-weight: 300;
          font-size: 36px;
          line-height: 49px;
        }

        .error-subheader {
          font-size: 16px;
          line-height: 22px;
        }

        .error-contact-text {
          font-size: 18px;
          line-height: 24px;
        }

        .action-button-error {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }

        span {
          font-weight: 700;
        }
      }
    }
  }

  app-error-dialog {
    .mat-dialog-content {
      .error-dialog-title {
        font-size: 24px;
        letter-spacing: 0.1px;
        line-height: 32px;
      }

      .error-dialog-content {
        .mat-expansion-panel {
          .mat-expansion-panel-header {
            .mat-expansion-panel-header-title {
              font-size: 14px;
              letter-spacing: 0.01px;
              line-height: 20px;
            }
          }

          ::ng-deep .mat-expansion-panel-body {
            font-size: 14px;
            letter-spacing: 0.01px;
            line-height: 20px;
          }
        }
      }
    }
  }

  app-update-config-group-button {
    .group-button {
      .simple-button {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;

        &:disabled {
          letter-spacing: 0.3px;
        }
      }

      .undo-button,
      .test-button,
      .deploy-button {
        @extend .simple-button;
      }

      .save-button {
        @extend .simple-button;
        line-height: 20px;
      }
    }
  }

  .cdk-overlay-container {
    .mat-autocomplete-panel .custom-options {
      span.mat-option-text {
        font-size: 14px;
      }
    }
  }

  app-radio-button {
    .title {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
    }

    mat-radio-group {
      font-size: 14px;
    }
  }

  // Template library

  .template-library .mat-tree-node {
    .mat-icon-button {
      line-height: unset;
    }

    &.active-node {
      font-weight: 600;

      &.active-node.hovering-node {
        font-weight: 600;
      }
    }
  }

  app-template-library {
    .mat-card-content .template-type-field,
    .mat-card-content .search-field {
      font-size: 16px;
    }
  }

  .template-library-box.cdk-drag-preview {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: 400;
  }

  app-zone-level-edit-dialog {
    app-select + mat-error,
    app-chips + mat-error {
      font-size: 12px;
      font-family: $NA-font-family;
      line-height: 19px;
    }
  }

  app-component-edit-dialog {
    .cross-field-error {
      font-size: 12px;
      font-family: $NA-font-family;
      line-height: 19px;
    }
  }

  app-config-level-edit-dialog {
    app-chips + mat-error {
      font-size: 12px;
      font-family: $NA-font-family;
    }

    .asset-type-content {
      .asset-type-field {
        font-size: 14px;
      }

      mat-error,
      mat-hint {
        font-size: 12px;
      }
    }
  }

  .asset-view-image-field {
    ::ng-deep .mat-form-field-infix {
      .mat-select {
        font-size: 14px;
      }
    }
  }

  .close-icon {
    cursor: pointer;
    color: #5b6770;

    &:hover {
      color: #292929;
    }
  }

  .inspection-type-description-dialog {
    .dialog-content {
      li {
        font-family: $NA-font-family;
        font-size: 16px;
        line-height: 22px;

        & {
          font-weight: 400;
        }

        span {
          font-weight: 600;
        }
      }
    }
  }

  .inspection-type-category-field {
    .info-description-icon {
      font-size: 20px;
    }
  }

  .tooltip-content {
    font-family: $NA-font-family;
    font-weight: 400;
  }

  .camera-icon {
    font-family: 'Material Icons Outlined' !important;
    font-size: 32px;
  }

  .photo-title {
    font-family: $NA-font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  .photo .form-description {
    font-family: $NA-font-family;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .photo-amount-label {
    font-family: $NA-font-family;
    font-size: 12px;
    font-weight: 400;
  }
}
