@use "@angular/material" as mat;
// @import "@angular/material/theming";
@use "sass:map";

@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import "@zonar-ui/sidenav/zonar-ui-sidenav.theme";
@import "@zonar-ui/footer/zonar-ui-footer.theme";

@import "partials/color.scss";
@import "partials/theme.scss";

@include zonar-ui-sidenav-theme($zonar-default-theme);
@include zonar-ui-core-theme($zonar-default-theme);
@include zonar-ui-footer-component-theme($zonar-default-theme);

@mixin apply-zonar-theme($theme, $eu-region) {
  @include mat.all-component-themes($theme);

  // Custom component themes added here...
  @include ect-custom-component($theme, $eu-region);

  @include zonar-ui-core-theme($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme, false);
}

.zonar-conti-theme {
  $custom-accent: map.get($zonar-conti-theme, "accent");
  $custom-accent: map.merge(
    $custom-accent,
    (
      "default": #ffa500,
      "lighter": #ffa500,
      "darker": #e38704,
      "contrast": map.set(map.get($custom-accent, "contrast"), "default", #454545),
    )
  );
  $custom-primary: map.get($zonar-conti-theme, "primary");
  $custom-primary: map.merge(
    $custom-primary,
    (
      300: #cdcdcd,
      600: #969696,
      "contrast": map.set(map.get($custom-primary, "contrast"), 300, #454545),
    )
  );
  $custom-foreground: map.get($zonar-conti-theme, "foreground");
  $custom-foreground: map.merge(
    $custom-foreground,
    (
      "base": #454545,
      "text": #454545,
    )
  );
  $zonar-conti-theme: map.merge(
    $zonar-conti-theme,
    (
      "primary": $custom-primary,
      "accent": $custom-accent,
      "foreground": $custom-foreground,
    )
  );
  @include apply-zonar-theme($zonar-conti-theme, true);
}

.zonar-dark-theme {
  @include apply-zonar-theme($ect-dark-theme, false);
}
