.mat-select-panel.custom-panel {
  margin-top: 35px !important;

  .mat-option {
    height: 45px;
  }
}

.cdk-overlay-container {
  .mat-autocomplete-panel .custom-options {
    height: 40px;
  }
}

.cdk-overlay-container {
  .mat-tooltip {
    word-break: break-word;
    white-space: normal;
  }
}

.custom-dialog-container .mat-dialog-container {
  padding: 0px;
}

/*
Try to override the change of @zonar-ui/material that will add a padding to button wrapper
https://gitlab.com/ZonarSystems/pattern-library/zonar-ui-material/-/blob/main/projects/zonar-ui-material/src/lib/partials/_material_overrides.scss#L5
*/
.mat-raised-button, .mat-stroked-button, .mat-flat-button, .mat-button-base, .mat-button-wrapper {
  padding: 0;
  margin: 0;
  line-height: inherit;
}

.cdk-overlay-backdrop .cdk-overlay-backdrop-showing {
  .custom-backdrop-showing {
    opacity: 0;
  }
}

.cdk-overlay-container {
  .mat-snack-bar-container {
    min-height: 45px;
    padding: 10px 16px;
    max-width: 800px !important;
  }

  .mat-divider {
    margin: 12px 16px;
  }

  .mat-select-panel {
    scrollbar-width: thin;

    .option-wrapper {
      scrollbar-width: thin;
    }
  }

  .mat-menu-panel {
    max-width: 100%;
  }
}

.publish-confirm-dialog {
  .mat-dialog-container {
    width: 360px;
    height: 205px;
  }
}

.remove-asset-view-image-dialog {
  .mat-dialog-container {
    .mat-dialog-title {
      .mat-icon {
        display: none;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
}

:root {
  scrollbar-width: thin;
}

.thin-scrollbar {
  scrollbar-width: thin;
}

.truncate-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-piece-dialog {
  .mat-dialog-container {
    .mat-dialog-title {
      .mat-icon {
        display: none;
      }
    }
  }
}

.add-piece-dialog {
  .mat-dialog-container {
    width: 360px;
    height: fit-content;
  }
}

.inspection-type-select {
  margin-top: 30px;
  margin-left: 5px;
  min-width: calc(100% + 23px) !important;
}

.add-premade-field-dialog {
  .mat-dialog-container {
    width: 360px;
    height: fit-content;
  }
}

.piece-edit-dialog {
  .mat-dialog-container {
    width: 360px;
    height: fit-content;
  }
}

.mat-chip.mat-standard-chip {
  font-weight: unset;
}

.mat-checkbox-layout {
  .mat-checkbox-label {
    white-space: pre-line;
  }
}

.mat-dialog-actions .mat-button {
  height: 32px;
}

.multi-line-tooltip {
  white-space: pre-line !important;
  position: relative;
}

.edit-inspection-type-confirm-dialog {
  .mat-dialog-container {
    width: 360px;
    height: fit-content;

    app-dialog .dialog-content {
      width: 312px;
    }

    app-dialog .mat-dialog-actions {
      min-height: initial;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

.zonar-conti-theme {
  app-template-library {
    app-template-library-tree mat-tree mat-tree-node .node-content {
      padding-top: 3px;
    }
    .template-library-container .template-type-field .mat-form-field-infix .mat-form-field-label{
      padding-bottom: 0px;
      padding-top: -4px;
    }

    .template-library-box.cdk-drag-preview .node-content{
      padding-top: 3px;
    }
  }

  app-tree mat-tree mat-tree-node .node-content{
    padding-top: 3px;
  }

  app-inspection-detail-row-checkbox-edit-dialog mat-dialog-content mat-form-field mat-error{
    padding-top: 7px;
  }

  app-chips .app-chip-list .chip-content{
    padding-top: 2px;
  }

  app-zone-level-edit-dialog app-select + mat-error {
    margin-top: -27px !important;
  }

  app-inspection-type-detail {
    .no-form-detail-message{
      padding: 7px 15px 5px 15px !important;
    }

    .detail-row mat-checkbox .mat-checkbox-label{
      margin-top: 2px;
    }
  }

  .company-select-panel .box-search input{
    padding: 7px 10px 3px 10px;
  }

  app-update-config-group-button {
    .save-button {
      min-width: 67px;
    }

    .test-button {
      min-width: 64px;
    }

    .undo-button {
      min-width: 72px;
    }

    .deploy-button {
      min-width: 86px;
    }
  }

  .companies.company {
    margin-top: 48px;
    & > .ng-star-inserted {
      margin-bottom: 21px;
    }
  }

  .custom-position-tooltip {
    top: -3px;
    line-height: 13px;
  }

  app-grid-zone-box {
    .inner-content {
      .box-active {
        .box-name {
          top: 54%;
        }
      } 
    }
  }

  .cdk-drag-preview {
    &.inner-content {
      .box-active {
        .box-name {
          top: 54%;
        }
      } 
    }
  }
}

.zonar-default-theme {
  app-update-config-group-button {
    .save-button {
      min-width: 90px;
    }

    .test-button {
      min-width: 66px;
    }

    .undo-button {
      min-width: 76px;
    }

    .deploy-button {
      min-width: 88px;
    }
  }
  
  .companies.company {
    margin-top: 40px;
    & > .ng-star-inserted {
      margin-bottom: -1px;
    }
  }

  .custom-position-tooltip {
    top: -5px;
    line-height: 16px;
  }

  .custom-menu-option-tooltip {
    min-width: 263px;
  }
}
