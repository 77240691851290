@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin apply-bg-fg($background, $foreground) {
  background-color: mat.get-color-from-palette($background, 'background');
  color: mat.get-color-from-palette($foreground, 'base');
}

@mixin apply-color-pair-bg($color, $hue: default, $opacity: null) {
  background-color: mat.get-color-from-palette($color, $hue, $opacity);
  color: mat.get-contrast-color-from-palette($color, $hue);
}

@mixin apply-color-pair-fg($color, $hue: default) {
  color: mat.get-color-from-palette($color, $hue);
  background-color: mat.get-contrast-color-from-palette($color, $hue);
}

@mixin ect-spinner($primary-color, $accent-color, $eu-region) {
  .spinner {
    background-color: rgba(mat.get-color-from-palette($primary-color, 100), 0.7);
    .mat-progress-spinner {
      circle {
        @if not $eu-region {
          stroke: mat.get-color-from-palette($accent-color, default);
        }
      }
    }
  }

  app-spinner {
    .blocked {
      .mat-progress-spinner {
        circle {
          @if not $eu-region {
            stroke: mat.get-color-from-palette($accent-color, default);
          }
        }
      }
    }

    .loading > .blocked {
      color: mat.get-color-from-palette($primary-color, 'darker-contrast');
    }
  }

  app-asset-zone-map {
    .spinner {
      background-color: transparent;
      .mat-progress-spinner {
        circle {
          @if not $eu-region {
            stroke: mat.get-color-from-palette($accent-color, default);
          }
        }
      }
    }
  }
}

@mixin apply-outline-color-box-dragging($primary-color, $accent-color, $eu-region) {
  @if $eu-region {
    outline-color: solid mat.get-color-from-palette($primary-color, 900);
    border-color: #454545;
  } @else {
    outline-color: solid mat.get-color-from-palette($accent-color, 500);
    border-color: #004a84;
  }
}

@mixin apply-choise-bg-dragging($eu-region) {
  @if ($eu-region) {
    background-image: url('../../assets/icons/drop-icon-dragging-eu.svg') !important;
  } @else {
    background-image: url('../../assets/icons/drop-icon-dragging-na.svg') !important;
  }
}

@mixin ect-context-menu($primary-color, $accent-color, $eu-region, $foreground) {
  .menu-option {
    &:hover {
      @if $eu-region {
        background-color: rgba(0, 78, 175, 0.05) !important;
      } @else {
        background-color: mat.get-color-from-palette(
          $accent-color,
          default,
          0.1
        ) !important;
      }
    }

    @if $eu-region {
      .mat-ripple-element {
        background-color: rgba(0, 78, 175, 0.1);
      }
    }

    &.mat-menu-item.disabled {
      color: mat.get-color-from-palette($foreground, 'disabled');
      &:hover {
        background-color: initial !important;
      }
    }
  }

  .mat-checkbox-inner-container {
    background-color: transparent !important;
  }

  .dialog-content {
    @if not $eu-region {
      .mat-checkbox-frame {
        border-color: mat.get-color-from-palette($accent-color);
      }

      .mat-checkbox-label {
        color: #000;
      }
    }
  }

  app-menu {
    @if $eu-region {
      .material-icons.more_horiz {
        color: mat.get-color-from-palette($primary-color, 900);
        opacity: 0.4;

        &:hover {
          color: mat.get-color-from-palette($primary-color, 900);
          opacity: 1;
        }
      }
    } @else {
      .material-icons.more_horiz {
        color: mat.get-color-from-palette($primary-color);
        opacity: 0.4;

        &:hover {
          color: mat.get-color-from-palette($primary-color, 50);
          opacity: 1;
        }
      }
    }
  }
}

@mixin ect-page-title($primary-color) {
  .title-content {
    h1 {
      color: mat.get-color-from-palette($primary-color, 900);
    }
  }
}

@mixin ect-page-background($primary-color) {
  .zonar-default-theme,
  app-default-layout,
  mat-sidenav-content,
  .sidenav-content-container {
    background-color: mat.get-color-from-palette($primary-color, 200);
  }
}

@mixin ect-navigation-tab($primary-color, $accent-color) {
  .navigation-tab {
    .mat-tab-link.ng-star-inserted {
      color: mat.get-color-from-palette($primary-color, 'text');
    }
    .mat-tab-link.ng-star-inserted.mat-tab-label-active {
      color: mat.get-color-from-palette($primary-color, 900);
    }
  }
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    @include apply-color-pair-bg($accent-color);
  }
}

@mixin ect-asset-zone-map(
  $primary-color,
  $accent-color,
  $background-color,
  $foreground-color,
  $eu-region
) {
  .asset-zone-map {
    @include apply-bg-fg($background-color, $foreground-color);
    border: 2px solid mat.get-color-from-palette($accent-color);

    .default-background {
      background-color: mat.get-color-from-palette($primary-color, 200);
    }

    .inner-content {
      .box-active {
        border-color: white;
        @if $eu-region {
          @include apply-color-pair-bg($accent-color);
        } @else {
          @include apply-color-pair-bg($accent-color);
        }

        &:hover {
          @if $eu-region {
            background: mat.get-color-from-palette($accent-color, 'darker');
            color: mat.get-color-from-palette($primary-color, 'darker-contrast');
          } @else {
            background: mat.get-color-from-palette($accent-color, 500);
            color: mat.get-color-from-palette($primary-color, 'darker-contrast');
          }
        }
      }

      .box-name {
        @if $eu-region {
          color: mat.get-color-from-palette($primary-color, 900);
        } @else {
          color: mat.get-color-from-palette($primary-color, 100);
        }
      }

      .multiple-box-name {
        color: mat.get-color-from-palette($primary-color);
        background-color: mat.get-color-from-palette($primary-color, 100);
      }
    }

    .asset-zone-name {
      color: mat.get-color-from-palette($primary-color);
    }
  }

  .cdk-drag-preview {
    .box-active {
      @if $eu-region {
        @include apply-color-pair-bg($accent-color, 'darker');
      } @else {
        @include apply-color-pair-bg($accent-color, 500);
      }

      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
      .box-name {
        @if $eu-region {
          color: mat.get-color-from-palette($primary-color, 900);
        } @else {
          color: mat.get-color-from-palette($primary-color, 100);
        }
      }

      .multiple-box-name {
        color: mat.get-color-from-palette($primary-color);
      }
    }
  }
}

@mixin ect-edit-dialog(
  $primary-color,
  $accent-color,
  $warn-color,
  $background-color,
  $eu-region
) {
  .mat-form-field-appearance-outline {
    &.mat-focused .mat-form-field-outline-thick,
    .mat-form-field-outline-thick {
      color: mat.get-color-from-palette($accent-color);
    }
  }

  .zone-edit-form {
    .mat-form-field,
    .mat-form-field.app-chip-list {
      .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($accent-color);
      }

      & .mat-form-field-flex:hover,
      .mat-form-field-flex:active {
        mat-label {
          color: mat.get-color-from-palette($accent-color);
        }
      }
    }

    .mat-form-field {
      &.ng-invalid {
        .mat-form-field-flex:hover {
          mat-label {
            color: #ce0100;
          }
        }
      }
    }
  }

  .app-chip-list {
    &.error-chip-list {
      .mat-form-field-label,
      .mat-form-field-outline,
      .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($warn-color);
      }
    }
  }

  .primary-button {
    background-color: transparent;
    @if $eu-region {
      color: mat.get-color-from-palette($primary-color, 900);
    } @else {
      color: mat.get-color-from-palette($accent-color);
    }
    border: 1px solid mat.get-color-from-palette($primary-color, 500);

    &.mat-button-disabled:disabled {
      color: mat.get-color-from-palette($primary-color, 600); // may be wrong
      border: 1px solid mat.get-color-from-palette($primary-color, 300);
      cursor: not-allowed;
    }
  }

  .special-button {
    @include apply-color-pair-bg($accent-color);

    &.mat-button-disabled:disabled {
      background-color: mat.get-color-from-palette($primary-color, 300);
      color: mat.get-color-from-palette($primary-color, 600);
      cursor: not-allowed;
    }
  }

  .save-button {
    border-color: rgba(255, 255, 255, 0.0001);

    &.special-button.spinner-loading {
      @include apply-color-pair-bg($accent-color);
    }

    .mat-progress-spinner.mat-accent circle {
      @if $eu-region {
        stroke: mat.get-color-from-palette($primary-color, 900);
      } @else {
        stroke: mat.get-color-from-palette($primary-color, 'darker-contrast');
      }
    }

    &:hover {
      @if $eu-region {
        background-color: #e38704;
      } @else {
        background-color: mat.get-color-from-palette($accent-color, 500);
      }
    }
  }

  .test-button,
  .deploy-button {
    &.primary-button.spinner-loading {
      background-color: transparent;
      border-color: mat.get-color-from-palette($primary-color, 500);
    }

    .mat-progress-spinner.mat-accent circle {
      @if $eu-region {
        stroke: mat.get-color-from-palette($primary-color, 900);
      } @else {
        stroke: mat.get-color-from-palette($accent-color, default);
      }
    }

    &:hover {
      @if $eu-region {
        background-color: rgba(0, 78, 175, 0.05);
      } @else {
        background-color: rgba(0, 117, 180, 0.1);
      }
    }
  }

  .cdk-overlay-container {
    .mat-divider {
      border-top-color: mat.get-color-from-palette($primary-color, 300);
    }
  }

  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      background-color: mat.get-color-from-palette($primary-color, 400);

      .mat-slide-toggle-thumb {
        background-color: mat.get-color-from-palette($primary-color);
      }
    }
  }

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      @if $eu-region {
        background-color: #ffdead;
      } @else {
        background-color: #b4cdf7;
      }

      .mat-slide-toggle-thumb {
        background-color: mat.get-color-from-palette($accent-color);
      }
    }
  }

  .cdk-overlay-container {
    .mat-option {
      &:hover {
        @if $eu-region {
          :not(.mat-option-disabled) {
            background-color: rgba(0, 78, 175, 0.05) !important;
          }

          &.mat-focus-indicator {
            .mat-option-text {
              background: transparent !important;
            }
          }
        }

        .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
          @if $eu-region {
            background-color: mat.get-color-from-palette($primary-color) !important;
          } @else {
            background-color: mat.get-color-from-palette($accent-color) !important;
          }
        }
      }

      &:hover:not(.mat-option-disabled) {
        background: unset !important;
      }

      @if $eu-region {
        &:focus {
          .mat-ripple-element {
            background-color: rgba(0, 78, 175, 0.1) !important;
          }
        }
      }

      .mat-pseudo-checkbox {
        color: mat.get-color-from-palette($primary-color);
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
        @if $eu-region {
          background-color: mat.get-color-from-palette($primary-color);
        } @else {
          background-color: mat.get-color-from-palette($accent-color);
        }
      }

      @if $eu-region {
        &.mat-active {
          background-color: rgba(0, 78, 175, 0.15) !important;
        }
      }

      &.mat-option-multiple.mat-active {
        background-color: transparent !important;
      }
    }
  }

  app-inspection-type-edit-dialog {
    .inspection-type-category-field {
      .info-description-icon {
        color: white;
        background-color: mat.get-color-from-palette($background-color, 'flat-button');

        &:hover {
          color: mat.get-color-from-palette($background-color, 'flat-button');
          background-color: white;
        }
      }
    }
  }
}

@mixin ect-tree-component(
  $primary-color,
  $accent-color,
  $background-color,
  $foreground-color,
  $eu-region
) {
  app-template-library-tree {
    .template-library {
      mat-tree {
        @include apply-bg-fg($background-color, $foreground-color);

        mat-tree-node {
          @include apply-color-pair-bg($primary-color, 300);
          @if $eu-region {
            outline-color: #cdcdcd;
            border-color: #cdcdcd;
          } @else {
            outline-color: #b9bec1;
            border-color: #b9bec1;
          }

          &:hover {
            @include apply-outline-color-box-dragging(
              $primary-color,
              $accent-color,
              $eu-region
            );
            @if $eu-region {
              outline-color: white !important;
              border-color: #454545 !important;
            } @else {
              outline-color: white !important;
              border-color: #004a84 !important;
            }
          }
        }
      }
    }
  }

  app-tree {
    .data-configuration {
      @include apply-bg-fg($background-color, $foreground-color);

      mat-tree {
        @include apply-bg-fg($background-color, $foreground-color);

        mat-tree-node {
          @include apply-color-pair-bg($primary-color, 300);
          @if $eu-region {
            outline-color: #cdcdcd;
            border-color: #cdcdcd;
          } @else {
            outline-color: #e2e4e6;
            border-color: #e2e4e6;
          }

          &:hover {
            @include apply-outline-color-box-dragging(
              $primary-color,
              $accent-color,
              $eu-region
            );
            @if $eu-region {
              outline-color: white;
              border-color: #454545;
            } @else {
              outline-color: white;
              border-color: #004a84;
            }
          }

          &.active-node {
            @include apply-color-pair-bg($accent-color);
            @if $eu-region {
              outline-color: orange;
              border-color: orange;
            } @else {
              outline-color: #0075b4;
              border-color: #0075b4;
            }

            &:hover {
              @include apply-outline-color-box-dragging(
                $primary-color,
                $accent-color,
                $eu-region
              );
              @if $eu-region {
                outline-color: white;
              } @else {
                outline-color: white;
              }
            }

            &.active-node.hovering-node {
              // Hover outline color should same as draging outline color.
              @include apply-outline-color-box-dragging(
                $primary-color,
                $accent-color,
                $eu-region
              );
              @if $eu-region {
                outline-color: white;
                border-color: #454545;
              } @else {
                outline-color: white;
                border-color: #004a84;
              }
            }

            .material-icons.more_horiz {
              @if $eu-region {
                color: mat.get-color-from-palette($primary-color, 900);
                opacity: 0.5;

                &:hover {
                  color: mat.get-color-from-palette($primary-color, 900);
                  opacity: 1;
                }
              } @else {
                color: mat.get-color-from-palette($primary-color, 50);
                opacity: 0.5;

                &:hover {
                  color: mat.get-color-from-palette($primary-color, 50);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .default-error {
    @include apply-color-pair-bg($primary-color, 200);
  }

  .mat-tree-node.cdk-drag-preview {
    @include apply-outline-color-box-dragging($primary-color, $accent-color, $eu-region);
    @include apply-color-pair-bg($primary-color, 300);
    color: #454545;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    @if $eu-region {
      outline-color: #454545;
      border-color: transparent;
    } @else {
      outline-color: #004a84;
      border-color: transparent;
    }
  }

  .mat-tree-node.template-library-box.cdk-drag-preview {
    @if not $eu-region {
      background-color: mat.get-color-from-palette($primary-color, 500);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
      outline-color: #004a84;
      border-color: transparent;
    } @else {
      @include apply-color-pair-bg($primary-color, 600);
      color: #454545;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
      outline-color: #454545;
      border-color: transparent;
    }
  }

  .mat-tree-node.cdk-drag-preview.active-node {
    @include apply-color-pair-bg($accent-color);
    @include apply-outline-color-box-dragging($primary-color, $accent-color, $eu-region);
    border-color: transparent;
  }
}

@mixin ect-error-page($primary-color) {
  .error-page {
    @include apply-color-pair-bg($primary-color, 'darker');

    .page-text {
      .action-button-error {
        background: transparent;
        color: mat.get-color-from-palette($primary-color, 'darker-contrast');
        border: 1px solid mat.get-color-from-palette($primary-color, 'darker-contrast');
      }

      .technical-information {
        &::before {
          background-color: mat.get-color-from-palette($primary-color, 'darker-contrast');
        }

        span {
          color: mat.get-color-from-palette($primary-color, 'darker-contrast');
        }
      }
    }
  }

  app-no-permission {
    .error-page {
      background-color: transparent;
      color: mat.get-color-from-palette($primary-color, 'lighter-contrast');
    }
  }

  .disabled-action-button {
    pointer-events: none;
  }
}

@mixin ect-error-dialog($primary-color) {
  app-error-dialog {
    .error-dialog-title {
      color: mat.get-color-from-palette($primary-color, 'text');
    }

    .mat-expansion-panel-header-title {
      color: mat.get-color-from-palette($primary-color, 'darker');
    }

    .error-item-title {
      color: mat.get-color-from-palette($primary-color);
    }

    .error-item-content {
      color: mat.get-color-from-palette($primary-color, 'text');
    }

    .mat-divider {
      border-top-color: mat.get-color-from-palette($primary-color, 300);
    }

    ::-webkit-scrollbar-thumb {
      background: mat.get-color-from-palette($primary-color, 300);
    }
  }
}

@mixin ect-notification-snackbar($primary-color, $eu-region) {
  .cdk-overlay-container {
    .mat-snack-bar-container {
      @include apply-color-pair-bg($primary-color, 50);

      @if $eu-region {
        color: mat.get-color-from-palette($primary-color, 900);

        &.failed-notification-snackbar {
          border: 2px solid #ff2d37;
        }

        &.success-notification-snackbar {
          border: 2px solid #2db928;
        }

        .error-outline-icon {
          color: #ff2d37;
        }

        .check-circle-outline-icon {
          color: #2db928;
        }
      } @else {
        &.failed-notification-snackbar {
          border: 2px solid #ce0100;
        }

        &.success-notification-snackbar {
          border: 2px solid #1d811e;
        }

        .error-outline-icon {
          color: #ce0100;
        }

        .check-circle-outline-icon {
          color: #1d811e;
        }
      }
    }
  }
}

@mixin ect-inspection-type($primary-color, $accent-color, $eu-region, $background-color) {
  app-inspection-type-card {
    .mat-card {
      border-color: mat.get-color-from-palette($primary-color, 600);
      color: mat.get-color-from-palette($primary-color);

      &.active {
        border-color: mat.get-color-from-palette($accent-color);
        color: mat.get-color-from-palette($primary-color, 900);

        @if not $eu-region {
          .card-title {
            color: mat.get-color-from-palette($accent-color);
          }
        }
      }
      &:hover {
        @if $eu-region {
          border-color: mat.get-color-from-palette($accent-color, 'darker');
          color: mat.get-color-from-palette($accent-color, 'lighter-contrast');
          border-width: 2px;
        } @else {
          border-color: mat.get-color-from-palette($accent-color, 500);
          color: mat.get-color-from-palette($accent-color, 'lighter-contrast');
          border-width: 2px;

          .mat-card-title {
            span {
              color: mat.get-color-from-palette($accent-color, default);
            }
          }
        }
      }
    }
  }

  .main-details {
    .form-description {
      color: mat.get-color-from-palette($primary-color);
    }

    .detail-cell {
      &:nth-child(3) {
        // border-left: 1px solid #e2e4e6;
        &::before {
          background-color: #e2e4e6;
        }
      }
    }
  }

  .additional-information {
    color: mat.get-color-from-palette($primary-color, 'text') span {
      color: #5b6770;
    }
  }

  .unit-switcher-container {
    .unit-item {
      @if $eu-region {
        background-color: #cdcdcd;
        color: #454545;
        border: 1px solid #454545;
      } @else {
        color: #292929;
        background-color: #e2e4e6;
        border: 1px solid #b9bec1;
      }

      &.active {
        @if $eu-region {
          @include apply-color-pair-bg($accent-color);
        } @else {
          background-color: #b9bec1;
        }
      }
    }
  }

  .edit-inspection-type-confirm-dialog {
    .mat-dialog-title .dialog-title {
      @if $eu-region {
        color: #454545;
      } @else {
        color: #292929;
      }
    }

    .mat-dialog-content .dialog-content {
      @if $eu-region {
        color: #454545;
      } @else {
        color: #49555e;
      }
    }
  }
}

@mixin ect-template-library($primary-color, $accent-color, $eu-region) {
  .search-field.mat-form-field-appearance-outline.mat-focused
    .mat-form-field-outline-thick {
    color: transparent;
  }
  .search-field.mat-form-field-appearance-outline.mat-focused .search-icon {
    color: mat.get-color-from-palette($accent-color);
  }

  .search-field {
    .mat-form-field-outline {
      @include apply-color-pair-bg($primary-color, 200);
      color: transparent;
    }

    input::placeholder {
      color: #5b6770;
    }

    input:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 0 0px 1000px #f3f4f5 inset !important;
      }
    }
  }

  .template-library-error {
    @include apply-color-pair-bg($primary-color, 200);
  }

  .template-library-container {
    app-spinner {
      .blocked {
        background-color: mat.get-color-from-palette($primary-color, 50) !important;
      }
    }
  }

  .mat-checkbox {
    &.mat-checkbox-disabled {
      opacity: 0.5;
    }
  }
  app-template-library {
    mat-progress-spinner {
      &.mat-progress-spinner.spin-directive {
        circle {
          @if $eu-region {
            stroke: #6b6b6b;
          } @else {
            stroke: #5b6770;
          }
        }
      }
    }
  }
}

@mixin ect-template-library-tree($primary-color, $eu-region) {
  app-template-library-tree {
    .template-library {
      mat-tree {
        mat-tree-node {
          @if $eu-region {
            @include apply-color-pair-bg($primary-color, 600);
            color: #454545;
          } @else {
            @include apply-color-pair-bg($primary-color, 500);
            // Overwrite the color to match the design
            color: mat.get-color-from-palette($primary-color, 900);
          }
        }
      }
    }
  }
}

@mixin ect-add-piece-dialog() {
  .error-icon {
    color: #ce0100;
  }
}

@mixin ect-dropdown-row-edit-dialog($primary-color, $accent-color, $eu-region) {
  // TODO: Change the correct color palette
  .drop-choice-button,
  .remove-choice-button,
  .add-choice-button {
    background-color: transparent;
    color: mat.get-color-from-palette($primary-color, 600);

    &:disabled {
      color: mat.get-color-from-palette($primary-color, 300);
    }
  }

  .dropdown-sub-title {
    color: mat.get-color-from-palette($primary-color, 700);
  }

  .choice-box {
    .drop-choice-button:hover {
      .drop-icon {
        @include apply-choise-bg-dragging($eu-region);
      }
    }
  }

  .choice-box.cdk-drag-preview {
    @if $eu-region {
      border: 2px solid mat.get-color-from-palette($primary-color, 900);
    } @else {
      border: 2px solid mat.get-color-from-palette($accent-color, 500);
    }

    .drop-choice-button {
      .drop-icon {
        @include apply-choise-bg-dragging($eu-region);
      }
    }
  }
}

@mixin ect-form-library($primary-color, $accent-color, $eu-region) {
  .premade-field-title {
    @if $eu-region {
      color: #454545;
    } @else {
      color: #49555e;
    }
  }

  .blank-form-field,
  .premade-field {
    @if $eu-region {
      @include apply-color-pair-bg($primary-color, 600);
      outline-color: #cdcdcd;
      border-color: #cdcdcd;
    } @else {
      @include apply-color-pair-bg($primary-color, 500);
      outline-color: #b9bec1;
      border-color: #b9bec1;
    }
    // Overwrite the color to match the design
    color: mat.get-color-from-palette($primary-color, 900);
    border: 2px solid transparent;

    &:hover {
      @include apply-outline-color-box-dragging(
        $primary-color,
        $accent-color,
        $eu-region
      );
      @if $eu-region {
        outline-color: white !important;
        border-color: #454545 !important;
      } @else {
        outline-color: white !important;
        border-color: #004a84 !important;
      }
    }
  }

  .form-library {
    mat-progress-spinner {
      &.mat-progress-spinner.spin-directive {
        circle {
          @if $eu-region {
            stroke: #6b6b6b;
          } @else {
            stroke: #5b6770;
          }
        }
      }
    }

    app-spinner {
      .blocked {
        background-color: mat.get-color-from-palette($primary-color, 50) !important;
      }
    }
  }

  .blank-form-field.cdk-drag-preview,
  .premade-field.cdk-drag-preview {
    @include apply-outline-color-box-dragging($primary-color, $accent-color, $eu-region);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    @if $eu-region {
      outline-color: #454545 !important;
      border-color: transparent !important;
    } @else {
      outline-color: #004a84 !important;
      border-color: transparent !important;
    }
  }

  .inspection-detail-wrapper .custom-placeholder {
    .drop-zone {
      background-color: mat.get-color-from-palette($primary-color, 200);
      border: 1px dashed mat.get-color-from-palette($accent-color) !important;
    }
  }

  .blank-form-field-list .custom-placeholder,
  .premade-field-list .custom-placeholder {
    @if $eu-region {
      background-color: mat.get-color-from-palette($primary-color, 200);
      border-color: white;
    } @else {
      background-color: #b9bec1;
      border-color: white;
    }
  }

  .no-premade-field-data {
    background-color: mat.get-color-from-palette($primary-color, 200);
  }
}

@mixin ect-inspection-type-detail($primary-color, $accent-color, $eu-region) {
  .no-form-detail-message {
    @include apply-color-pair-bg($primary-color, 200);
    color: mat.get-color-from-palette($primary-color, 700);
  }

  .detail-row.checkbox-row,
  .detail-cell.checkbox {
    .mat-checkbox {
      .mat-checkbox-inner-container {
        background-color: transparent;
      }
    }
  }
  .mat-checkbox.mat-checkbox-checked {
    .mat-checkbox-background {
      @if $eu-region {
        background-color: mat.get-color-from-palette($primary-color);
      } @else {
        background-color: mat.get-color-from-palette($accent-color);
      }
    }
  }

  .detail-box .photo {
    .detail-row {
      .photo-title {
        color: #5b6770;
      }

      .photo-amount {
        .photo-amount-label {
          color: #5b6770;
        }
      }

      .detail-cell.form-description {
        color: #5b6770;
      }
    }
  }
}

@mixin ect-tooltip($primary-color, $accent-color, $eu-region) {
  .tooltip-content {
    @if $eu-region {
      background-color: mat.get-color-from-palette($primary-color);
      color: white;
    } @else {
      background-color: #5b6770;
      color: white;
    }

    &:after {
      @if $eu-region {
        background-color: mat.get-color-from-palette($primary-color);
      } @else {
        background-color: #5b6770;
      }
    }
  }

  .tooltip-icon {
    color: mat.get-color-from-palette($accent-color) !important;
  }
}

@mixin ect-icons($primary-color, $accent-color, $eu-region) {
  .camera-icon {
    @if $eu-region {
      color: mat.get-color-from-palette($accent-color, 'darker');
    } @else {
      color: mat.get-color-from-palette($accent-color);
    }
  }

  .required-icon {
    @if $eu-region {
      background-image: ur('../../assets/icons/hard-required-eu.svg') !important;
    } @else {
      background-image: url('../../assets/icons/hard-required-na.svg') !important;
    }
  }
}

@mixin ect-custom-component($theme, $eu-region) {
  $config: mat-get-color-config($theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $background: map-get($config, 'background');
  $foreground: map-get($config, 'foreground');
  $warn: map-get($config, 'warn');
  // $custom-background-color: mat-color($primary, 200);
  // $custom-background-color: mat.get-color-from-palette($background, 'disabled-button-toggle');

  // @debug $config;

  background-color: mat.get-color-from-palette($background, 'disabled-button-toggle');

  @include ect-spinner($primary, $accent, $eu-region);
  @include ect-context-menu($primary, $accent, $eu-region, $foreground);
  @include ect-page-title($primary);
  @include ect-page-background($primary);
  @include ect-navigation-tab($primary, $accent);
  @include ect-asset-zone-map($primary, $accent, $background, $foreground, $eu-region);
  @include ect-edit-dialog($primary, $accent, $warn, $background, $eu-region);
  @include ect-tree-component($primary, $accent, $background, $foreground, $eu-region);
  @include ect-error-page($primary);
  @include ect-error-dialog($primary);
  @include ect-notification-snackbar($primary, $eu-region);
  @include ect-inspection-type($primary, $accent, $eu-region, $background);
  @include ect-template-library($primary, $accent, $eu-region);
  @include ect-template-library-tree($primary, $eu-region);
  @include ect-add-piece-dialog();
  @include ect-dropdown-row-edit-dialog($primary, $accent, $eu-region);
  @include ect-form-library($primary, $accent, $eu-region);
  @include ect-inspection-type-detail($primary, $accent, $eu-region);
  @include ect-tooltip($primary, $accent, $eu-region);
  @include ect-icons($primary, $accent, $eu-region);
}

::-webkit-scrollbar-thumb {
  background: #e2e4e6;
}

:root {
  scrollbar-color: #e2e4e6 white;
}
